@use '../../styles/typography' as *;
@use '../../styles/mixins.scss' as *;
@use '../../styles/variables.scss' as *;

.safety-cover {
    &__header {
        background-color: $accent-blue;
        padding: 2rem;
        text-align: center;

        &--title {
            color: #fff;
            font-size: 2.5rem;
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    &__description {
        padding: 1rem;
        margin: 1rem 0;
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0,0,0,0.2);
        text-align: justify;

        &--title {
            font-size: 1.75rem;
            color: $accent-blue;
            text-align: center;
        }

        &--sentence {
            font-size: 1.2rem;
            line-height: 1.75;
            padding: 0.5rem;
        }
    }

    &__colors {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin: 1rem 0;

        &--title {
            width: 100%;
            text-align: center;
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }

        &--gallery {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
        }

        &--container {
            flex-basis: 18%; // Flex basis set for 5 items in a row
            margin: 10px;
            box-shadow: 0 1px 4px rgba(0,0,0,0.1);
            text-align: center;

            .cover__title {
                color: $accent-blue;
                font-weight: bold;
            }

            .cover__img {
                width: 100%;
                height: auto;
                object-fit: cover;
                min-height: 200px;
            }
        }
    }
}

@include tablet {
    .safety-cover__colors--container {
        flex-basis: 45%; // Larger view for tablets, two items per row
    }
}

@include desktop {
    .safety-cover__description--container{
        margin: 1rem 4rem;
    }
    .safety-cover__colors--container {
        flex-basis: 18%; // Back to five items per row on desktops
    }
}
