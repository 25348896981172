@use './typography';
@use './variables.scss';

// Breakpoint variables and sizes for devices
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1280px;

// Breakpoint mixins
@mixin tablet {
    @media (min-width: $tablet-breakpoint) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $desktop-breakpoint) {
        @content;
    }    
}

// Fonts
    // Mobile Fonts
    @mixin mobile-header-one{
        font-family: "Montserrat", sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2rem;
    }

    @mixin mobile-header-two{
        font-family: "Montserrat", sans-serif;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.5rem;
    }

    @mixin mobile-header-three{
        font-family: "Montserrat", sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.25rem;
    }

    @mixin mobile-header-four{
        font-family: "Montserrat", sans-serif;
        font-size: 0.85rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.15rem;
    }

    @mixin mobile-header-five{
        font-family: "Montserrat", sans-serif;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.05rem;
    }

    @mixin mobile-body-one{
        font-family: "Montserrat", sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.50rem;
    }