@font-face{
    font-family: "Montserrat", sans-serif;
    src: url('../assets/Montserrat/static/Montserrat-Light.ttf') format("ttf");
    font-weight: 100;
}

@font-face{
    font-family: "Montserrat", sans-serif;
    src: url('../assets/Montserrat/static/Montserrat-Regular.ttf') format("ttf");
    font-weight: 400;
}

@font-face{
    font-family: "Montserrat", sans-serif;
    src: url('../assets/Montserrat/static/Montserrat-SemiBold.ttf') format("ttf");
    font-weight: 600;
}

@font-face{
    font-family: "Montserrat", sans-serif;
    src: url('../assets/Montserrat/static/Montserrat-Bold.ttf') format("ttf");
    font-weight: 700;
}