@use '../../styles/typography' as *;
@use '../../styles/mixins.scss' as *;
@use '../../styles/variables.scss' as *;

.spa {
    &__header {
        width: 100%;
        height: 50vh;
        background-image: url('../../media/images/marquis-spa.jpeg');
        background-position: center;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;

        &--title {
            background-color: rgba($accent-blue, 0.8); // Slightly transparent
            padding: 1rem;
            color: #fff;
            font-size: 2.5rem;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 1px;
            border-radius: 5px;
            text-decoration: underline 2px $accent-yellow;
        }
    }

    &__container {
        padding: 1rem;
        margin: 1rem 0;
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0,0,0,0.2);

        &--title {
            font-size: 1.75rem;
            color: $accent-blue;
            text-align: center;
            margin-bottom: 1rem;
        }

        &--description {
            font-size: 1rem;
            line-height: 1.75;
            text-align: justify;
        }
    }

    &__range, &__inground {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 1rem;
        margin-top: 1rem;

        &--img {
            flex-basis: 45%; // Allows for two images per row
            max-width: 300px; // Limiting the size to maintain aspect ratios
            margin: 10px;
            height: auto;
            border-radius: 5px;
        }
    }

    &__img {
        width: 100%;
        height: auto;
        margin-top: 2rem;
    }

    &__inground--header-img {
        width: 100%;
        height: 50vh;
        object-fit: cover;
    }
}

@include tablet {
    .spa {
        &__header {
            height: 60vh; // Slightly taller on tablet
        }

        &__container, &__range {
            flex-direction: row;
            justify-content: space-around;
        }
        
        &__range--img {
            flex-basis: 45%; // Adjust if necessary based on actual content width
        }
    }
}

@include desktop {
    .spa {
        &__header {
            height: 70vh; // Making the header larger on desktop
        }

        &__container {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &--description{
                font-size: 1.2rem; // Slightly larger font size for readability on tablets
                line-height: 1.8;
                margin: 1rem 4rem;
            }
        }

        &__range--img {
            flex-basis: 20%; // More space for images on larger screens
        }
    }
}
