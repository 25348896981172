@use '../../styles/typography' as *;
@use '../../styles/mixins.scss' as *;
@use '../../styles/variables.scss' as *;

.overview{
    box-sizing: border-box;
    height: 100vh;
    width: 100vw;
    background-image: url('../../media/images/finished-2.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    justify-content: center;
    align-content: space-between;

    &__title{
        @include mobile-header-two;
        color: #fff;
        border-bottom: 2px solid rgb(241, 210, 15);
        height: 3rem;
        display: flex;
        align-items: center;
        text-align: center;
    }

    &__footer{
        margin-bottom: 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: end;
    }

    &__word{
        margin: 1rem 1rem;
        @include mobile-header-two;
        font-size: 2rem;
        color: #000;
        text-decoration: 2px underline rgb(241, 210, 15);
    }


}

@include tablet{
    .overview{
        justify-content: start;

        &__title{
            text-align: left;
            margin: 1rem 3rem;
            height: 6rem;
            font-size: 2rem;
        }

        &__footer{
            width: 100%;
            flex-direction: row;
            justify-content: end;
            margin-bottom: 4rem;
        }

            &__word{
                font-size: 2.25rem;
            }
    }
}
