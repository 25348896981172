@use '../../styles/typography' as *;
@use '../../styles/mixins.scss' as *;
@use '../../styles/variables.scss' as *;

.homepage{
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &__header{
        box-sizing: border-box;
        width: 100vw;
        height: auto;
        background-color: #0D86CD;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
    }

        &__title{
            color: #fff;
            @include mobile-header-one;
            border-bottom: 2px solid transparent;
        }

        &__year{
            color: #fff;
            border-bottom: 2px solid rgb(241, 210, 15);
            @include mobile-header-one;
        }

    &__image{
        box-sizing: border-box;
        height: 82vh;
        width: 100vw;
        margin: 0;
        padding: 0;
        background-image: url('../../media/images/finished-1.jpeg');
        background-position: center;
        background-size: cover;
    }

    &__quote{
        box-sizing: border-box;
        width: 100vw;
        height: 10vh;
        height: auto;
        background-color: #0D86CD;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
    }

    &__attribute{
        color: #fff;
        border-bottom: 2px solid rgb(241, 210, 15);
        @include mobile-header-three;
    }
}

@include tablet{
    .homepage{

        &__header{
            padding: 1rem 3rem;
        }

        &__quote{
            padding: 0.5rem 3rem;
        }

            &__attribute{
                font-size: 1.25rem;
            }
    }
}