@use '../../styles/typography' as *;
@use '../../styles/mixins.scss' as *;
@use '../../styles/variables.scss' as *;

.equipment{
    box-sizing: border-box;
    margin: 0;
    width: 100vw;

    &__container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &--about{
            font-size: 1.2rem; // Slightly larger font size for readability on tablets
            line-height: 1.8; // Improved line spacing for easier reading
            margin: 1rem 1.25rem;
        }
    }

    &__header{
        height: 15vh;
        width: 100vw;
        background-color: #000;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
    }

    &__title{
        color: #fff;
        margin: 1rem 1rem;
        border-bottom:  2px solid rgb(241, 210, 15);
        @include mobile-header-two;
    }

    &__tile{
        display: flex; /* Set the tile as a flex container */
        flex-direction: column; /* Set the direction of the flex items */
        justify-content: space-between;
        border: 1px solid #d1d1d1;
        margin: 1rem;
        padding: 1rem;
        height: 50%;
        max-height: 650px;
        width: 70%;
        max-width: 550px;
        border-radius: 0.25rem;
    }

    &__lighting{
       height: 60vh;
       background-image: url('../../media/images/lighting.png');
       background-position: center;
       background-size: contain;
       background-repeat: no-repeat;
       margin: 1rem;
    }
}

.tile{

    &__container{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1rem;
    }

    &__img{
        width: 100%; /* Ensure the image takes up the full width of its container */
        height: auto; /* Maintain the aspect ratio of the image */
        max-width: 300px; /* Ensure the image does not exceed its container's width */
        max-height: 300px
    }

    &__category{
        @include mobile-header-four;
        margin: 0.5rem 0;
    }

    &__product{
        @include mobile-header-two;
        line-height: 1rem;
    }

    &__description{
        @include mobile-body-one;
    }

    &__button{
        width: 8rem;
        padding: 0.75rem 0.75rem;
        border-radius: 0.25rem;
        border: none;
        background-color: rgb(241, 210, 15);
        transition: ease-out 0.3s;
        cursor: pointer;
        align-self: flex-end;

        &:hover{
            background-color: #0D86CD;
        }
    }

        &__link{
            text-decoration: none;
            color: #000;
        }
}

@include tablet{
    .equipment{

        &__title{
            margin-left: 2rem;
        }

        &__container{
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-evenly;

            &--about{
                line-height: 1.75;
                margin: 1rem 2.25rem;
            }
        }

        &__tile{
            width: 40vw;
        }
    }

    .equipment__lighting{
        background-size: cover;
        background-position: top;
    }

    .tile{
        &__img{
            height: 275px;
            width: auto;
        }

        &__category{
            margin: 0.75rem 0;
        }

        &__product{
            margin: 1rem 0;
            line-height: 1.5;
        }

        &__description{
            line-height: 1.75;
        }
    }
}

@include desktop{
    .equipment__container{
        padding: 4rem;
        margin: 1rem auto;

        &--about{
            line-height: 2;
        }
    }
}