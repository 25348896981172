@use '../../styles/typography' as *;
@use '../../styles/mixins.scss' as *;
@use '../../styles/variables.scss' as *;
.quality-build {
    &__header {
        background-color: #000;
        padding: 0.25rem 0;
        text-align: center;

        &--title {
            color: #fff;
            font-size: 1.5rem;
            text-transform: uppercase;
            letter-spacing: 2px;
            text-decoration: underline 2px $accent-yellow;
            transition: all 0.3s ease;

            &:hover {
                color: $accent-yellow;
                cursor: pointer;
            }
        }
    }

    .materials {
        &__gunite, &__plumbing, &__liner, &__experience, &__under-liner-steps {
            padding: 2rem;
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.8);
            box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);

            &--title {
                font-weight: bold;
                margin-bottom: 1rem;
                color: $accent-blue;
                font-size: 1.8rem;
            }

            &--subtitle {
                font-weight: bold;
                margin-bottom: 0.5rem;
                font-size: 1.25rem;
            }

            &--about {
                text-align: justify;
                font-size: 1.1rem;
                line-height: 1.6;
            }

            &--container {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 1rem;
            }

            &--img, &--video {
                width: 100%;
                height: auto;
                max-width: 100%;
                border-radius: 8px;
                box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
                margin-bottom: 1rem;
            }
        }

        &__under-liner-steps--carousel {
            width: 100%;
            margin-top: 1rem;

            .carousel .slide {
                background: none;
            }

            .carousel .control-arrow,
            .carousel.carousel-slider .control-arrow {
                opacity: 0.5;
            }

            .carousel .control-arrow:hover,
            .carousel.carousel-slider .control-arrow:hover {
                background: rgba(0, 0, 0, 0.2);
                opacity: 1;
            }

            .carousel .control-dots .dot {
                background: #000;
                box-shadow: none;
            }

            .carousel .control-dots .dot.selected {
                background: $accent-yellow;
            }

            .carousel .legend {
                background: rgba(0, 0, 0, 0.5);
                color: #fff;
                font-size: 1rem;
                padding: 0.5rem;
                border-radius: 8px;
            }
        }
    }
}

@include tablet {
    .quality-build__header {
        padding: 2rem 0;

        &--title {
            font-size: 2.5rem;
            margin: 0 20px;
        }
    }

    .materials__header {
        padding: 8rem;
        background-size: cover;
        background-position: center;
    }

    .materials {
        &__plumbing, &__gunite, &__liner, &__experience, &__under-liner-steps {
            padding: 2rem;
            align-items: center;
            text-align: center;

            &--img, &--video {
                width: 100%;
                height: auto;
                margin-bottom: 1rem;
            }

            &--img {
                max-width: 800px;
            }

            &--about {
                font-size: 1.2rem;
                line-height: 1.8;
                margin: 1rem 4rem;
            }

            &--title {
                font-size: 1.75rem;
                margin-bottom: 1rem;
            }

            &--container {
                flex-direction: column;
                justify-content: center;
                width: 100%;
            }
        }
    }
}