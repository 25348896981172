@use '../../styles/typography' as *;
@use '../../styles/mixins.scss' as *;
@use '../../styles/variables.scss' as *;

.excavation {
    box-sizing: border-box;
    max-width: 100vw;
    margin: 0 auto;
    padding: 2rem 0;

    &__header {
        background-color: $accent-blue;
        padding: 2rem;
        text-align: center;

        &--title {
            color: #fff;
            font-size: 2.5rem;
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    &__video {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 1rem 0;

        &--video {
            width: 90%;
            max-width: 800px;
            height: auto;
            border-radius: 10px;
            box-shadow: 0 2px 8px rgba(0,0,0,0.1);
        }
    }

    &__protection, &__access {
        box-sizing: border-box;
        margin: 2rem 1rem;
        padding: 1rem;
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0,0,0,0.2);
        display: flex;
        flex-direction: column; // Default to column on mobile
        align-items: center;

        &--header {
            padding-bottom: 1rem;
            border-bottom: 2px solid $accent-yellow;
            width: 100%; // Ensure header spans full width
        }
        
        &--title {
            font-size: 1.75rem;
            color: $accent-blue;
            font-weight: bold;
        }

        &--description {
            box-sizing: border-box;
            font-size: 1rem;
            line-height: 1.75;
            padding: 0.5rem 1rem;
            text-align: justify;
            width: 100%; // Ensure descriptions take full width
        }
    }

    .protection__tile, .access__tile {
        border: none;
        padding: 1rem;
        margin: 1rem 0;
        background: #f9f9f9;
        border-radius: 5px;
        box-shadow: 0 1px 4px rgba(0,0,0,0.1);

        &--title {
            font-size: 1.25rem;
            margin-bottom: 0.5rem;
            color: $accent-blue;
        }

        &--img, &--video {
            width: 100%;
            height: auto;
            border-radius: 5px;
            box-shadow: 0 1px 4px rgba(0,0,0,0.1);
        }

        &--description {
            font-size: 1rem;
            line-height: 1.5;
            padding-top: 0.5rem;
        }

        &--list {
            list-style: none;
            padding: 0;
            margin-top: 1rem;
            font-size: 1rem;
            text-align: center;
        }

        &--item {
            margin: 0.5rem 0;
        }
    }
}

@include tablet {
    .excavation {
        &__protection, &__access {
            flex-direction: row; // Change to row layout for larger screens
            flex-wrap: wrap; // Allow wrapping if necessary
            justify-content: space-evenly; // Evenly space the tiles

            .protection__tile, .access__tile {
                width: 45%; // Adjust width to allow for two tiles per row
                height: 900px;
                margin: 10px; // Add margin for spacing between tiles
            }
        }
    }
}
    @include desktop {
        .excavation {
            &__protection, &__access {
                flex-direction: row; // Change to row layout for larger screens
                flex-wrap: wrap; // Allow wrapping if necessary
                justify-content: space-evenly; // Evenly space the tiles

                .protection__tile, .access__tile {
                    width: 45%; // Adjust width to allow for two tiles per row
                    margin: 10px; // Add margin for spacing between tiles
                }
            }

            &__access--description{
                font-size: 1.2rem; // Slightly larger font size for readability on tablets
                line-height: 1.8;
            }
        }
    }

// Additional adjustments for Desktop
@include desktop {
    .excavation {
        &__protection, &__access {
            &--gallery{
                display: flex;
                align-items: center;
                justify-content: space-evenly;
            }
            .protection__tile, .access__tile {
                width: 30%; // Adjust for three tiles per row if needed
            }
        }
    }
}